import { useMutation, UseMutationOptions } from 'react-query'

import { UPLOAD_ADS_FILE_MUTATION, UploadAdsFileMutationData, UploadAdsFileMutationVariables } from '../mutations'

export const useUploadAdsFile = (
  options?: UseMutationOptions<UploadAdsFileMutationData, Error, UploadAdsFileMutationVariables>,
) => {
  return useMutation<UploadAdsFileMutationData, Error, UploadAdsFileMutationVariables>(
    UPLOAD_ADS_FILE_MUTATION,
    options,
  )
}
