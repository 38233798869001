import { useMutation } from 'react-query'
import { FormException } from '@core/exceptions'

import {
  UPDATE_ADVERTISEMENT_PERMISSION,
  UpdateAdvertisementPermissionMutationValidationError,
  UpdateAdvertisementPermissionMutationVariables,
} from '../mutations'
import { queryClient } from '../../../index'

export const useUpdateAdvertisementPermissionMutation = () => {
  return useMutation<
    void,
    FormException<UpdateAdvertisementPermissionMutationValidationError>,
    UpdateAdvertisementPermissionMutationVariables
  >(UPDATE_ADVERTISEMENT_PERMISSION, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['places'])
    },
  })
}
