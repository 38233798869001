import { useMutation } from 'react-query'
import { FormException } from '@core/exceptions'

import { REMOVE_ADVERTISEMENT, RemoveAdvertisementMutationVariables } from '../mutations'
import { queryClient } from '../../../index'

export const useRemoveAdvertisementMutation = () => {
  return useMutation<void, FormException, RemoveAdvertisementMutationVariables>(REMOVE_ADVERTISEMENT, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['places'])
    },
  })
}
