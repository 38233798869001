import { useMutation } from 'react-query'
import { FormException } from '@core/exceptions'

import {
  CREATE_ADVERTISEMENT,
  CreateAdvertisementMutationValidationError,
  CreateAdvertisementMutationVariables,
} from '../mutations'
import { queryClient } from '../../../index'

export const useCreateAdvertisementMutation = () => {
  return useMutation<
    void,
    FormException<CreateAdvertisementMutationValidationError>,
    CreateAdvertisementMutationVariables
  >(CREATE_ADVERTISEMENT, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['places'])
    },
  })
}
