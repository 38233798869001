import { createContext, memo, useCallback, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import xor from 'lodash/xor'

import { GET_PROFILE, GetProfileQueryData, GetProfileQueryKey } from '../queries'
import { Profile } from '../interfaces'

export type IAuthContextValueData = { data?: Profile; isLoading: boolean; places: string[] }
export type IAuthContextValueHandlers = { togglePlaces: (ids: string[]) => void; setPlaces: (ids: string[]) => void }

export type IAuthContextValue = [IAuthContextValueData, IAuthContextValueHandlers]

export const AuthContext = createContext<IAuthContextValue>([
  { data: undefined, isLoading: true, places: [] },
  { togglePlaces: () => {}, setPlaces: () => {} },
])

export const AuthProvider = memo(({ children }) => {
  const [selectedPlaces, setPlaces] = useState<string[]>([])

  const { data, isLoading } = useQuery<GetProfileQueryData, Error, GetProfileQueryData, GetProfileQueryKey>(
    ['auth', 'profile'],
    GET_PROFILE,
  )

  const togglePlaces = useCallback((ids: string[]) => {
    setPlaces((prevState) => xor(prevState, ids))
  }, [])

  const value = useMemo<IAuthContextValue>(
    () => [
      { data, isLoading, places: selectedPlaces },
      { togglePlaces, setPlaces },
    ],
    [data, isLoading, selectedPlaces, togglePlaces],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
})
