import { ChangeEvent, MouseEvent, useCallback, useMemo, useState } from 'react'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  createStyles,
  Hidden,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import clsx from 'clsx'
import { useCreateAdvertisementMutation, useGetAdvertisementsQuery } from '@modules/advertisements'
import { useAuth } from '@modules/auth'
import { useUploadAdsFile } from '@modules/upload'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) =>
  createStyles({
    video: {
      maxWidth: '100%',
      width: '100%',
    },
    image: {
      width: '100%',
      maxWidth: '100%',
    },
    editArea: {
      width: '100%',
      paddingTop: '56.25%',
      backgroundColor: '#f0f0f0',
      position: 'relative',
      overflow: 'hidden',
    },
    editAreaContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      '& > img': {
        width: '100%',
      },
    },
    editAreaActionWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      pointerEvents: 'none',
      '& > *': {
        pointerEvents: 'auto',
      },
    },
    dimmedBox: {
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    menuIcon: {
      marginRight: theme.spacing(1),
    },
    textField: {
      marginBottom: theme.spacing(2),
      '& .MuiInputBase-input': {
        color: 'white',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    backButton: {
      position: 'absolute',
      top: theme.spacing(1),
      left: theme.spacing(1),
      color: '#000',
      zIndex: theme.zIndex.appBar
    },
  }),
)

export const AuthenticationAdvertisementPage = () => {
  const [{ places }] = useAuth()
  const [anchorEl, setAnchorEl] = useState<Element>()
  const classes = useStyles()
  const [{ data }] = useAuth()
  const history = useHistory()

  const [editing, setEditing] = useState<boolean>(false)
  const [skipEditing, setSkipEditing] = useState<boolean>(false)

  const [id, setId] = useState<number>()
  const [video, setVideo] = useState<string>()
  const [photo, setPhoto] = useState<string>()
  const [skipDuration, setSkipDuration] = useState<string>('5')

  const advertisements = useGetAdvertisementsQuery(
    { placeId: places[0], placement: 'authentication' },
    {
      onSuccess: (data) => {
        if (data.length) {
          const ads = data[0]

          if (ads.type === 'image') {
            setPhoto(ads.source)
          } else {
            setVideo(ads.source)
          }

          setId(ads.id)
          setSkipDuration(ads.skippableTime.toString())
        }
      },
    },
  )

  const isEditable = useMemo(() => {
    if (data?.role === 'medium') {
      return !!advertisements.data?.[0]?.editable
    } else {
      return true
    }
  }, [advertisements, data?.role])

  const uploadAdsFile = useUploadAdsFile()
  const createAdvertisement = useCreateAdvertisementMutation()

  const openDropdown = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const closeDropdown = useCallback(() => {
    setAnchorEl(undefined)
  }, [])

  const onVideoAdd = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const file = Array.from(event.target.files || [])[0]

        if (file) {
          uploadAdsFile.mutate(
            { file, type: 'ads' },
            {
              onSuccess: (url) => {
                createAdvertisement.mutate(
                  {
                    id,
                    placeId: places[0],
                    skippableTime: parseInt(skipDuration),
                    source: url,
                    type: 'video',
                    placement: 'authentication',
                  },
                  {
                    onSuccess: () => {
                      setSkipEditing(true)
                    },
                  },
                )
              },
            },
          )
        }

        event.target.files = null
      }
    },
    [createAdvertisement, id, places, skipDuration, uploadAdsFile],
  )

  const onPhotoAdd = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const file = Array.from(event.target.files || [])[0]

        if (file) {
          uploadAdsFile.mutate(
            { file, type: 'ads' },
            {
              onSuccess: (url) => {
                createAdvertisement.mutate(
                  {
                    id,
                    placeId: places[0],
                    skippableTime: parseInt(skipDuration),
                    source: url,
                    type: 'image',
                    placement: 'authentication',
                  },
                  {
                    onSuccess: () => {
                      setSkipEditing(true)
                    },
                  },
                )
              },
            },
          )
        }

        event.target.files = null
      }
    },
    [createAdvertisement, id, places, skipDuration, uploadAdsFile],
  )

  const onRemove = useCallback(() => {
    if (!advertisements.data?.length) {
      return null
    }

    const ad = advertisements.data[0]

    createAdvertisement.mutate({
      placeId: places[0],
      id: ad.id,
      placement: ad.placement,
      source: null,
      type: ad.type,
      skippableTime: 0,
    })
  }, [advertisements.data, createAdvertisement, places])

  const onSubmit = useCallback(() => {
    if (!photo && !video) {
      return null
    }

    createAdvertisement.mutate(
      {
        id,
        placeId: places[0],
        type: !!photo ? 'image' : 'video',
        placement: 'authentication',
        source: photo || video || '',
        skippableTime: parseInt(skipDuration),
      },
      {
        onSuccess: () => {
          setEditing(false)
          setSkipEditing(false)
        },
      },
    )
  }, [createAdvertisement, id, photo, places, skipDuration, video])

  const onSkipEdit = useCallback(() => {
    setSkipEditing(true)
    setEditing(true)
  }, [])

  if (!places.length) {
    return <Typography variant='h5'>İaşə obyekti seçin...</Typography>
  }

  if (advertisements.isLoading) {
    return null
  }

  if (!id) {
    return <Typography variant='h5'>Reklam üçün yer ayırılmayıb</Typography>
  }

  const content = (
    <Box position='relative'>
      {!!video && (
        <video className={classes.video} controls loop autoPlay muted>
          <source src={video} type='video/mp4' />
        </video>
      )}
      {!!photo && <img className={classes.image} src={photo} alt='' />}
      <Box position='absolute' bottom={100} right={24}>
        <IconButton onClick={onRemove} style={{ color: 'red' }}>
          <Icons.Delete />
        </IconButton>
        <Button disabled={!isEditable} onClick={onSkipEdit} variant='contained' startIcon={<Icons.Edit />}>
          {skipDuration} saniyə
        </Button>
      </Box>
    </Box>
  )

  const editDuration = (
    <Box textAlign='center'>
      <Typography style={{ color: '#fff', marginBottom: 12 }} variant='h6'>
        Keçid vaxtını təyin et
      </Typography>
      <TextField
        className={classes.textField}
        variant='outlined'
        color='primary'
        value={skipDuration}
        onChange={(e) => setSkipDuration(e.target.value)}
        type='number'
        fullWidth
        placeholder='Saniyə'
      />
      <Button onClick={onSubmit} size='large' fullWidth variant='contained' color='secondary'>
        Təsdiqlə
      </Button>
    </Box>
  )

  const uploadButton = (
    <Button
      variant='contained'
      color='secondary'
      size='large'
      startIcon={<Icons.Add />}
      endIcon={<Icons.ArrowDropDown />}
      onClick={openDropdown}
      disabled={!isEditable}
    >
      Təsvir yüklə
    </Button>
  )

  return (
    <Box height='100vh' display='flex' padding={3} alignItems='center' justifyContent='center'>
      <IconButton className={classes.backButton} onClick={() => history.go(-1)}>
        <Icons.ArrowBack />
      </IconButton>

      {uploadAdsFile.isLoading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      <Hidden xsUp implementation='css'>
        <input id='auth-ads-photo-upload' onChange={onPhotoAdd} type='file' />
        <input id='auth-ads-video-upload' onChange={onVideoAdd} type='file' />
      </Hidden>
      {!editing && (!!video || !!photo) && content}
      {!editing && !video && !photo && (
        <Box maxWidth='1080px' width='100%'>
          <Box className={classes.editArea}>
            <Box className={classes.editAreaContent}>
              <Box className={classes.editAreaActionWrapper}>{uploadButton}</Box>
            </Box>
          </Box>
        </Box>
      )}
      {editing && (
        <Box maxWidth='1080px' width='100%'>
          <Box className={classes.editArea}>
            <Box className={classes.editAreaContent}>
              {!!video && (
                <video className={classes.video} autoPlay muted loop>
                  <source src={video} />
                </video>
              )}
              {!!photo && <img className={classes.image} src={photo} alt='' />}
              <Box className={clsx(classes.editAreaActionWrapper, { [classes.dimmedBox]: skipEditing })}>
                {skipEditing && editDuration}
                {!skipEditing && !video && !photo && uploadButton}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={closeDropdown}>
        <MenuItem htmlFor='auth-ads-photo-upload' component='label'>
          <Icons.Photo className={classes.menuIcon} /> Şəkil
        </MenuItem>
        <MenuItem htmlFor='auth-ads-video-upload' component='label'>
          <Icons.Videocam className={classes.menuIcon} /> Video
        </MenuItem>
      </Menu>
    </Box>
  )
}
