import { FC, forwardRef, useCallback } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { MenuItem } from '@material-ui/core'

export const MenuLinkItem: FC<NavLinkProps> = (props) => {
  const { onClick, className, activeClassName, activeStyle, exact, strict, isActive, location, to } = props

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const component = useCallback(
    forwardRef<any, any>((props) => (
      <NavLink
        to={to}
        activeClassName={activeClassName}
        activeStyle={activeStyle}
        exact={exact}
        strict={strict}
        isActive={isActive}
        location={location}
        {...props}
      />
    )),
    [activeClassName, activeStyle, exact, isActive, location, strict, to],
  )

  return (
    <MenuItem onClick={onClick} className={className} component={component} button>
      {props.children}
    </MenuItem>
  )
}
