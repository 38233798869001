import { createMuiTheme } from '@material-ui/core'

export const theme = createMuiTheme({
  palette: {
    text: {
      primary: '#000000',
      secondary: '#000000',
    },
    primary: {
      main: '#4db6ac',
      light: '#82e9de',
      dark: '#00867d',
    },
    secondary: {
      main: '#6200EE',
      light: '#7417ff',
      dark: '#5800d6',
    },
  },
})
