import { ChangeEvent, FC, FormEvent, useCallback, useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Button,
  Grid,
  InputAdornment,
  Link,
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  makeStyles,
  createStyles,
  TextField,
} from '@material-ui/core'
import * as Icons from '@material-ui/icons'

import { useLoginMutation } from '../hooks'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('lg')]: {
        height: '100vh',
      },
    },
    image: {
      maxWidth: '100%',
      width: 289,
    },
  }),
)

export const LoginPage: FC = () => {
  const styles = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)

  const [values, setValues] = useState<{ email: string; password: string }>({ email: '', password: '' })

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues((prevData) => ({ ...prevData, [event.target.name]: event.target.value }))
  }, [])

  const { mutate, isLoading, error } = useLoginMutation()

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      mutate(values)
    },
    [mutate, values],
  )

  return (
    <Grid className={styles.root} container>
      <Grid xs={12} lg={7} item>
        <Box display='flex' height='100%' justifyContent='center' alignItems={isDesktop ? 'center' : 'start'}>
          <img
            className={styles.image}
            src='/adify.png'
            alt=''
          />
        </Box>
      </Grid>
      <Grid xs={12} lg={5} item>
        <Box
          display='flex'
          height='100%'
          component='form'
          alignItems={isDesktop ? 'center' : undefined}
          paddingX={isDesktop ? 12 : 1}
          borderLeft={isDesktop ? `1px solid ${theme.palette.grey.A100}` : undefined}
          onSubmit={onSubmit}
        >
          <Grid direction='column' spacing={3} container>
            <Grid item>
              <Box paddingBottom={4}>
                <Typography align='center' variant='h4'>
                  Sign in
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <TextField
                id='emailInput'
                name='email'
                label='Email'
                placeholder='Emaili daxil edin...'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Icons.AccountCircle />
                    </InputAdornment>
                  ),
                }}
                onChange={onInputChange}
                value={values.email}
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                id='passwordInput'
                name='password'
                label='Şifrə'
                placeholder='Şifrəni daxil edin...'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Icons.Lock />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment onClick={() => setPasswordVisible((value) => !value)} position='end'>
                      {passwordVisible ? <Icons.VisibilityOff /> : <Icons.Visibility />}
                    </InputAdornment>
                  ),
                }}
                onChange={onInputChange}
                value={values.password}
                type={passwordVisible ? 'password' : 'text'}
                fullWidth
              />
            </Grid>
            {!!error && (
              <Grid item>
                <Typography color='error'>{error.message}</Typography>
              </Grid>
            )}
            <Box display='none'>
              <Typography >Adify new version v2</Typography>
            </Box>
            <Grid item>
              <Link color='secondary' component={NavLink} to='/'>
                <Typography align='center'>Forgot password?</Typography>
              </Link>
            </Grid>
            <Grid item>
              <Button disabled={isLoading} type='submit' size='large' variant='contained' color='secondary' fullWidth>
                Daxil ol
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}
