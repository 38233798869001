export const getDisplayPlacement = (placement: string) => {
  switch (placement) {
    case 'login':
      return 'Daxil ol'
    case 'authentication':
      return 'Reklam izləyərək daxil ol'
    case 'survey':
      return 'Sorğu'
    case 'menu':
      return 'Menyu'
    default:
      return 'Digər'
  }
}
