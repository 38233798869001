import { ChangeEvent, useCallback, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { useCloseModal } from '@infra/hooks'
import { baseFormStyles } from '@infra/styles'
import { useUploadFile } from '@modules/upload'
import { useCreatePlaceMutation } from '@modules/places'
import { Alert } from '@material-ui/lab'

export type CreatePlaceFormValues = {
  name: string
  address: string
  phoneNumber: string
  avatar: string | null
}

const useStyles = makeStyles((theme) => ({
  ...baseFormStyles(theme),
  avatarBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: theme.spacing(4),
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(0),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
}))

export const CreatePlace = () => {
  const close = useCloseModal()
  const classes = useStyles()
  const uploadPhoto = useUploadFile()
  const createPlace = useCreatePlaceMutation()

  const [values, setValues] = useState<CreatePlaceFormValues>({
    name: '',
    address: '',
    phoneNumber: '+994',
    avatar: null,
  })

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues((prevState) => ({ ...prevState, [event.target.name]: event.target.value }))
  }, [])

  const onAvatarRemove = useCallback(() => {
    setValues((prevState) => ({ ...prevState, avatar: null }))
  }, [])

  const onAvatarAdd = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        uploadPhoto.mutate(
          { type: 'logo', file: Array.from(event.target.files || [])[0] },
          {
            onSuccess: (url) => {
              setValues((prevState) => ({ ...prevState, avatar: url }))
            },
          },
        )
        event.target.files = null
      }
    },
    [uploadPhoto],
  )

  const onSubmit = useCallback(() => {
    createPlace.mutate(values, { onSuccess: () => close('/users') })
  }, [close, createPlace, values])

  return (
    <Dialog scroll='body' onClose={() => close('/users')} open={true}>
      <DialogTitle disableTypography>
        <Typography variant='h6'>Yeni iaşə obyekti əlavə et</Typography>
        <IconButton onClick={() => close('/users')} className={classes.close}>
          <Icons.Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {createPlace.error?.errors?.avatar && (
          <Alert severity='warning' variant='outlined'>
            Avatar: {createPlace.error?.errors?.avatar}
          </Alert>
        )}
        <Box className={classes.box}>
          <Box className={classes.avatarBox}>
            <Hidden xsUp implementation='css'>
              <input id='create-place-avatar-upload' onChange={onAvatarAdd} type='file' />
            </Hidden>
            <Box className={classes.avatar}>
              <Avatar src={values.avatar || undefined}>{values.name.substring(0, 2)}</Avatar>
            </Box>
            {values.avatar ? (
              <IconButton onClick={onAvatarRemove} color='primary' className={classes.uploadButton}>
                <Icons.Delete />
              </IconButton>
            ) : (
              <label htmlFor='create-place-avatar-upload'>
                <IconButton
                  disabled={uploadPhoto.isLoading}
                  component='span'
                  color='primary'
                  className={classes.uploadButton}
                >
                  {!uploadPhoto.isLoading ? <Icons.CloudUpload /> : <CircularProgress />}
                </IconButton>
              </label>
            )}
          </Box>
          <Box flex={1}>
            <Grid spacing={2} container>
              <Grid xs={12} item>
                <TextField
                  fullWidth
                  error={!!createPlace.error?.errors?.name}
                  helperText={createPlace.error?.errors?.name}
                  value={values.name}
                  onChange={onInputChange}
                  name='name'
                  variant='outlined'
                  label='Ad'
                  placeholder='İaşə obyektinin adını daxil edin...'
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  fullWidth
                  error={!!createPlace.error?.errors?.address}
                  helperText={createPlace.error?.errors?.address}
                  onChange={onInputChange}
                  value={values.address}
                  name='address'
                  variant='outlined'
                  label='Ünvan'
                  placeholder='İaşə obyektinin ünvanını daxil edin...'
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  fullWidth
                  error={!!createPlace.error?.errors?.phoneNumber}
                  helperText={createPlace.error?.errors?.phoneNumber}
                  value={values.phoneNumber}
                  onChange={onInputChange}
                  name='phoneNumber'
                  variant='outlined'
                  label='Mobil telefon'
                  placeholder='İaşə obyektinin mobil telefonunu daxil edin...'
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} color='primary'>
          Əlavə et
        </Button>
      </DialogActions>
    </Dialog>
  )
}
