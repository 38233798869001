import { useMutation } from 'react-query'
import { LOGOUT } from '@modules/auth/mutations'
import { queryClient } from '../../../index'

export const useLogoutMutation = () => {
  return useMutation<void, Error>(LOGOUT, {
    onMutate: async () => {
      await queryClient.setQueryData(['auth', 'profile'], () => undefined)
    },
  })
}
