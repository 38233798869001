import { FC } from 'react'
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'

export type TableHeadCell = {
  id: string
  align?: 'left' | 'right'
  width?: number
  disableSort?: boolean
  label?: string
}

export type EnhancedTableHeadProps = {
  order: 'asc' | 'desc'
  orderBy: string
  onSort: (event: any, id: string) => void
  headCells: TableHeadCell[]
}

export const EnhancedTableHead: FC<EnhancedTableHeadProps> = ({ order, orderBy, onSort, headCells }) => {
  const createSortHandler = (property) => (event) => {
    onSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(({ id, align, label, width, disableSort }) => (
          <TableCell
            width={width}
            key={id}
            align={align}
            sortDirection={!disableSort && orderBy === id ? order : false}
          >
            <TableSortLabel
              active={orderBy === id}
              direction={orderBy === id ? order : 'asc'}
              onClick={createSortHandler(id)}
              disabled={disableSort}
            >
              {label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
