import { createStyles, Theme } from '@material-ui/core'

export const baseFormStyles = (theme: Theme) =>
  createStyles({
    avatar: {
      position: 'relative',
      height: 56 * 2 + 16,
      width: 56 * 2 + 16,
      borderRadius: '50%',
      overflow: 'hidden',

      '& .MuiAvatar-root': {
        height: '100%',
        width: '100%',
      },
    },
    close: {
      position: 'absolute',
      top: 6,
      right: 12,
    },
    box: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
      },
    },
    avatarOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.4)',
    },
    avatarBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: theme.spacing(4),
      position: 'relative',
      [theme.breakpoints.up('lg')]: {
        paddingBottom: theme.spacing(0),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
      },
    },
    uploadButton: {
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%',
    },
  })
