import { Fragment, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  createStyles,
  Divider,
  FormControlLabel,
  Hidden,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Menu,
  TextField,
  Typography,
} from '@material-ui/core'
import { useAuth, useLogoutMutation } from '@modules/auth'
import { usePlacesQuery } from '@modules/places/hooks'
import { Place } from '@modules/places'
import xor from 'lodash/xor'

const useStyles = makeStyles((theme) =>
  createStyles({
    menu: {
      '& .MuiPaper-root': {
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(0),
        minWidth: 312,
      },
    },
    button: {
      display: 'inline-flex',
      width: 'auto',
      paddingTop: 4,
      paddingBottom: 4,
    },
    denseUser: {
      paddingLeft: 2,
    },
  }),
)

export const UserSelection = () => {
  const classes = useStyles()
  const [auth, authHandlers] = useAuth()
  const logout = useLogoutMutation()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedPlaces, setSelectedPlaces] = useState<string[]>([])
  const [search, setSearch] = useState<string>('')

  const places = usePlacesQuery({ enabled: auth.data?.role !== 'medium' })
  const selectedSinglePlace = useMemo<Place | undefined>(() => {
    if (auth.places.length === 1 && !!places.data?.length) {
      return places.data.find((place) => selectedPlaces.includes(place.id))
    }
  }, [auth.places.length, places.data, selectedPlaces])

  const placesOptions = useMemo<Place[] | undefined>(() => {
    return auth.data?.role !== 'medium' ? places.data : auth.data?.places || []
  }, [auth.data?.places, auth.data?.role, places.data])

  const filteredPlacesOptions = useMemo<Place[] | undefined>(() => {
    const data = placesOptions

    if (search) {
      return data?.filter((item) => item.name.includes(search))
    }

    return data
  }, [placesOptions, search])

  const onSelectPlace = useCallback((id: string) => {
    setSelectedPlaces((prevState) => xor(prevState, [id]))
  }, [])

  const onSelectAll = useCallback(() => {
    setSelectedPlaces((prevState) =>
      prevState.length !== placesOptions?.length ? placesOptions?.map((place) => place.id) || [] : [],
    )
  }, [placesOptions])

  const resetSelectedPlaces = useCallback(() => {
    setSelectedPlaces([])
    authHandlers.setPlaces([])
    setAnchorEl(null)
  }, [authHandlers])

  const onSubmit = useCallback(() => {
    authHandlers.setPlaces(selectedPlaces)
    setAnchorEl(null)
    setSearch('')
  }, [authHandlers, selectedPlaces])

  useEffect(() => {
    setSelectedPlaces(auth.places)
  }, [auth.places])

  if (!auth.data) {
    return null
  }

  const avatar = (
    <Avatar variant='rounded' color='secondary' src={auth.data.avatar || undefined}>
      {!auth.data.avatar && auth.data.name.substring(0, 2)}
    </Avatar>
  )

  const placeAvatar = (
    <Avatar variant='rounded' color='secondary' src={selectedSinglePlace?.avatar || undefined}>
      {selectedSinglePlace?.name}
    </Avatar>
  )

  return (
    <Fragment>
      <ListItem
        onClick={(event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
        className={classes.button}
        button
      >
        <Hidden lgUp>{avatar}</Hidden>
        {!selectedSinglePlace && (
          <Hidden mdDown>
            <ListItemAvatar>{avatar}</ListItemAvatar>
            <ListItemText
              primary={auth.data.name}
              secondary={!!selectedPlaces.length ? `${selectedPlaces.length} obyekt` : auth.data.email}
            />
          </Hidden>
        )}
        {!!selectedSinglePlace && (
          <Hidden mdDown>
            <ListItemAvatar>{placeAvatar}</ListItemAvatar>
            <ListItemText primary={selectedSinglePlace.name} />
          </Hidden>
        )}
      </ListItem>
      <Menu anchorEl={anchorEl} open={!!anchorEl} className={classes.menu} onClose={() => setAnchorEl(null)}>
        <Box paddingBottom={2}>
          <Typography align='center'>{auth.data.name}</Typography>
          <Typography align='center' variant='subtitle2'>
            {auth.data.email}
          </Typography>
          <Button onClick={() => logout.mutate()} size='small' color='secondary' fullWidth>
            Çıxış
          </Button>
        </Box>
        {auth.data.role !== 'medium' && (
          <Box paddingBottom={1}>
            <Button onClick={resetSelectedPlaces} fullWidth variant='outlined' color='primary'>
              Super admin olaraq izlə
            </Button>
          </Box>
        )}
        <Box paddingBottom={1}>
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
            label='Axtar'
            variant='outlined'
            margin='dense'
            color='primary'
          />
        </Box>
        {!!filteredPlacesOptions?.length ? (
          <Box display='flex' flexDirection='column'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedPlaces.length === (placesOptions?.length || 0)}
                  indeterminate={selectedPlaces.length !== 0 && selectedPlaces.length < (placesOptions?.length || 0)}
                  onChange={() => onSelectAll()}
                />
              }
              label='Hamısını seç'
            />
            <Divider />
            {filteredPlacesOptions?.map((place) => (
              <FormControlLabel
                key={place.id}
                control={
                  <Checkbox checked={selectedPlaces.includes(place.id)} onChange={() => onSelectPlace(place.id)} />
                }
                label={
                  <ListItem className={classes.denseUser}>
                    <ListItemAvatar>
                      <Avatar variant='rounded' color='secondary' src={place.avatar || undefined}>
                        {!place.avatar && place.name.substring(0, 2)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={place.name} secondary={place.address} />
                  </ListItem>
                }
              />
            ))}
            <Divider />
            <Box paddingTop={1}>
              <Button onClick={onSubmit} color='secondary' fullWidth>
                Təsdiqlə
              </Button>
            </Box>
          </Box>
        ) : (
          <Typography align='center'>Məlumat tapılmadı...</Typography>
        )}
      </Menu>
    </Fragment>
  )
}
