import { QueryFunction } from 'react-query'
import { Place } from '../interfaces'
import { placeToDomain } from './index'

export type GetPlaceByIdQueryData = Place
export type GetPlaceByIdQueryKey = ['places', string]

export const GET_PLACE_BY_ID: QueryFunction<GetPlaceByIdQueryData, GetPlaceByIdQueryKey> = async ({ queryKey }) => {
  const [, id] = queryKey

  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1/locations/${id}/`, {
    credentials: 'include',
  })

  if (response.ok) {
    const { body } = await response.json()
    return placeToDomain(body)
  } else {
    const { message } = await response.json()

    throw new Error(message)
  }
}
