import { FC, useCallback } from 'react'
import { Advertisement } from '../interfaces'
import { Box, Button, createStyles, Grid, IconButton, makeStyles, Paper, Typography } from '@material-ui/core'
import { getDisplayPlacement } from '@modules/advertisements/helpers'
import * as Icons from '@material-ui/icons'
import {
  useCreateAdvertisementMutation,
  useRemoveAdvertisementMutation,
  useUpdateAdvertisementPermissionMutation,
} from '@modules/advertisements'

export type AdsPermissionsProps = {
  placeId: string
  data: Record<string, Advertisement[]>
}

const useStyles = makeStyles((theme) =>
  createStyles({
    close: {
      position: 'absolute',
      top: 6,
      right: 12,
    },
    adsCard: {
      height: 172,
      minHeight: 172,
      maxHeight: 172,
      borderRadius: theme.spacing(1),
      overflow: 'hidden',
      position: 'relative',
    },
    adsThumbnail: {
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100%',
      width: '100%',
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
      },
    },
    adsButton: {
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%',
    },
    deleteButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      color: theme.palette.error.main,
    },
    createButton: {
      width: '100%',
      height: '100%',
    },
  }),
)

export const AdsPermissions: FC<AdsPermissionsProps> = ({ data, placeId }) => {
  const classes = useStyles()

  const createAd = useCreateAdvertisementMutation()
  const removeAd = useRemoveAdvertisementMutation()
  const updateAdPermission = useUpdateAdvertisementPermissionMutation()

  const onCreateAd = useCallback(
    ({ placement }) => {
      createAd.mutate({
        placeId: placeId,
        placement,
        type: 'image',
        skippableTime: 0,
        editable: false,
      })
    },
    [createAd, placeId],
  )

  const onChangeAdEditable = useCallback(
    ({ id, editable }) => {
      updateAdPermission.mutate({
        id,
        placeId,
        editable,
      })
    },
    [placeId, updateAdPermission],
  )

  const onRemoveAd = useCallback(
    (id: number) => {
      removeAd.mutate({ placeId, advertisementId: id })
    },
    [placeId, removeAd],
  )

  return (
    <Paper elevation={1}>
      <Box paddingX={2} paddingY={1} position='relative'>
        <Box paddingY={1}>
          <Grid spacing={2} container>
            {Object.entries(data).map(([placement, group]) => (
              <Grid key={placement} xs={12} item>
                <Box>
                  <Typography variant='subtitle2'>{getDisplayPlacement(placement)}</Typography>
                  <Box paddingTop={1}>
                    <Grid spacing={2} container>
                      {group.map((ads) => (
                        <Grid key={ads.id} xs={12} lg={4} item>
                          <Box className={classes.adsCard}>
                            <div className={classes.adsThumbnail} style={{ backgroundImage: `url(${ads.source})` }} />
                            <Button
                              onClick={() => onChangeAdEditable({ id: ads.id, editable: !ads.editable })}
                              variant='contained'
                              className={classes.adsButton}
                              color='secondary'
                            >
                              {ads.editable ? 'İcazə verilib' : 'İcazə ver'}
                            </Button>
                            <IconButton onClick={() => onRemoveAd(ads.id)} className={classes.deleteButton}>
                              <Icons.Delete />
                            </IconButton>
                          </Box>
                        </Grid>
                      ))}
                      <Grid xs={12} lg={4} item>
                        <Button
                          onClick={() => onCreateAd({ placement })}
                          variant='contained'
                          className={classes.createButton}
                          disabled={group.length >= 1 && placement === 'authentication'}
                        >
                          <Icons.Add />
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Paper>
  )
}
