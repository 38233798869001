import { createPortal } from 'react-dom'
import { FC, useEffect, useRef } from 'react'

export const TitlePortal: FC = ({ children }) => {
  const containerElementRef = useRef(document.createElement('div'))

  useEffect(() => {
    const containerElement = containerElementRef.current
    const rootNode = document.querySelector('#appbar-title-portal-area')

    if (rootNode) {
      rootNode.appendChild(containerElement)
    }

    return () => {
      rootNode?.removeChild(containerElement)
    }
  }, [])

  return createPortal(children, containerElementRef.current)
}
