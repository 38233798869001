import { FC, forwardRef, useCallback } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { createStyles, ListItem, makeStyles } from '@material-ui/core'
import { rgba } from 'polished'

const useStyles = makeStyles((theme) =>
  createStyles({
    navLink: {
      '&.active': {
        backgroundColor: rgba(theme.palette.primary.main, 0.1),
        '& .MuiTypography-root': {
          fontWeight: 500,
        },
      },
    },
  }),
)

export const ListLinkItem: FC<NavLinkProps & { disabled?: boolean }> = (props) => {
  const classes = useStyles()

  const { activeClassName, activeStyle, exact, strict, isActive, location, to, disabled } = props

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const component = useCallback(
    forwardRef((props, ref) => {
      return (
        <NavLink
          to={to}
          activeClassName={activeClassName}
          activeStyle={activeStyle}
          exact={exact}
          strict={strict}
          isActive={isActive}
          location={location}
          ref={ref as any}
          {...props}
        />
      )
    }),
    [activeClassName, activeStyle, exact, isActive, location, strict, to],
  )

  return (
    <ListItem disabled={disabled} className={classes.navLink} component={component} button>
      {props.children}
    </ListItem>
  )
}
