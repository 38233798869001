import { QueryFunction } from 'react-query'
import type { Advertisement, AdvertisementPlacement } from '../interfaces'

export type GetAdvertisementsQueryData = Advertisement[]
export type GetAdvertisementsQueryKey = [
  'places',
  string | undefined,
  'advertisements',
  Partial<{ placement: AdvertisementPlacement }>,
]

export const advertisementToDomain = (item): Advertisement => ({
  id: item.id,
  type: item.format === 1 ? 'image' : 'video',
  placement: getDomainAdsPlacement(item.type),
  source: item.file,
  skippableTime: item.timer,
  editable: !!item.perm,
})

export const getDomainAdsPlacement = (placement: number): AdvertisementPlacement => {
  switch (placement) {
    case 1:
      return 'login'
    case 2:
      return 'authentication'
    case 3:
      return 'menu'
    default:
      return 'other'
  }
}

export const getPersistenceAdsPlacement = (placement: AdvertisementPlacement) => {
  switch (placement) {
    case 'login':
      return 1
    case 'authentication':
      return 2
    case 'menu':
      return 3
    case 'other':
      return 4
  }
}

export const GET_ADVERTISEMENTS: QueryFunction<GetAdvertisementsQueryData, GetAdvertisementsQueryKey> = async ({
  queryKey,
}) => {
  const [, placeId, , { placement }] = queryKey

  if (!placeId) {
    throw new Error('İaşə obyekti daxil edilməyib')
  }

  const persistenceType = placement ? getPersistenceAdsPlacement(placement) : null

  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/v1/locations/${placeId}/media/${
      persistenceType ? `?type=${persistenceType}` : ''
    }`,
    {
      credentials: 'include',
    },
  )

  if (response.ok) {
    const { body } = await response.json()
    return body.map((item) => advertisementToDomain(item))
  } else {
    const { message } = await response.json()

    throw new Error(message)
  }
}
