import { Crop } from 'react-image-crop'

export const getCroppedImg = (image: HTMLImageElement, crop: Crop) => {
  if (
    typeof crop.width === 'undefined' ||
    typeof crop.height === 'undefined' ||
    typeof crop.x === 'undefined' ||
    typeof crop.y === 'undefined'
  ) {
    throw new Error('Crop is incorrect')
  }

  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = crop.width
  canvas.height = crop.height
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('Canvas context cannot be created')
  }

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  )

  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          return reject('Blob cannot be created')
        }

        resolve(blob)
      },
      'image/jpeg',
      1,
    )
  })
}
