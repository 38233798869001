import { MutationFunction } from 'react-query'
import { FormException } from '@core/exceptions'
import { clearEmptyString } from '@core/utils'

export type CreatePlaceMutationVariables = {
  id?: string
  name: string
  address: string
  phoneNumber: string
  avatar: string | null
}

export type CreatePlaceMutationValidationError = Partial<{
  id: string
  name: string
  address: string
  phoneNumber: string
  avatar: string
}>

export const CREATE_PLACE: MutationFunction<void, CreatePlaceMutationVariables> = async ({
  id,
  name,
  address,
  phoneNumber,
  avatar,
}) => {
  const body = JSON.stringify(
    clearEmptyString({
      id,
      name,
      address,
      phone: phoneNumber,
      logo: avatar,
    }),
  )

  const url = !id
    ? `${process.env.REACT_APP_API_HOST}/api/v1/locations/`
    : `${process.env.REACT_APP_API_HOST}/api/v1/locations/${id}/`

  const method = id ? 'PUT' : 'POST'

  const response = await fetch(url, {
    method,
    body,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })

  if (response.status === 400) {
    const { message } = await response.json()

    throw new FormException<CreatePlaceMutationValidationError>('Məlumatlar yalnış daxil edilib.', {
      id: message.id?.join('. '),
      name: message.name?.join('. '),
      address: message.address?.join('. '),
      phoneNumber: message.phone?.join('. '),
      avatar: message.photo?.join('. '),
    })
  } else if (!response.ok) {
    const { message } = await response.json()
    throw new FormException(message)
  }
}
