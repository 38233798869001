import { QueryFunction } from 'react-query'
import { Place } from '@modules/places'

export type GetPlacesQueryData = Place[]
export type GetPlacesQueryKey = ['places']

export const placeToDomain = (place) => ({
  id: place.id,
  name: place.name,
  address: place.address,
  phoneNumber: place.phone,
  telegramBotLink: place.telegram_bot_link,
  menuUrl: place.menu_url,
  avatar: place.logo,
  rating: place.rating,
})

export const GET_PLACES: QueryFunction<GetPlacesQueryData, GetPlacesQueryKey> = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1/locations/`, {
    credentials: 'include',
  })

  if (response.ok) {
    const { body } = await response.json()
    return body.results.map((item) => placeToDomain(item))
  } else {
    const { message } = await response.json()

    throw new Error(message)
  }
}
