import { MutationFunction } from 'react-query'
import { FormException } from '@core/exceptions'
import { clearEmptyString } from '@core/utils'

import { AdvertisementPlacement, AdvertisementType } from '../interfaces'
import { getPersistenceAdsPlacement } from '@modules/advertisements'

export type CreateAdvertisementMutationVariables = {
  id?: number
  placeId: string
  placement: AdvertisementPlacement
  source?: string | null
  type: AdvertisementType | null
  skippableTime: number
  editable?: boolean
}

export type CreateAdvertisementMutationValidationError = Partial<{
  placement: string
  source: string
  type: string
  skippableTime: string
  perm: string
}>

export const CREATE_ADVERTISEMENT: MutationFunction<void, CreateAdvertisementMutationVariables> = async ({
  id,
  placeId,
  placement,
  source,
  type,
  skippableTime,
  editable,
}) => {
  const body = JSON.stringify(
    clearEmptyString({
      type: getPersistenceAdsPlacement(placement),
      file: source,
      format: type === 'image' ? 1 : type === 'video' ? 2 : null,
      timer: skippableTime,
      perm: editable,
    }),
  )

  const url = id
    ? `${process.env.REACT_APP_API_HOST}/api/v1/locations/${placeId}/media/${id}/`
    : `${process.env.REACT_APP_API_HOST}/api/v1/locations/${placeId}/media/`

  const method = id ? 'PUT' : 'POST'

  const response = await fetch(url, {
    method,
    body,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })

  if (response.status === 400) {
    const { message } = await response.json()

    throw new FormException<CreateAdvertisementMutationValidationError>('Məlumatlar yalnış daxil edilib.', {
      placement: message.type?.join('. '),
      type: message.format?.join('. '),
      source: message.file?.join('. '),
      skippableTime: message.timer?.join('. '),
    })
  } else if (!response.ok) {
    const { message } = await response.json()
    throw new FormException(message)
  }
}
