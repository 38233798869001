import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { useHistory, useParams } from 'react-router-dom'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  createStyles,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import ReactCrop, { Crop } from 'react-image-crop'

import { useUploadAdsFile } from '@modules/upload'

import { useCreateAdvertisementMutation, useGetAdvertisementsQuery } from '@modules/advertisements'

import { useAuth } from '@modules/auth'
import { getCroppedImg } from '@core/utils'

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      position: 'relative',
    },
    bannerContainer: {},
    banner: {
      position: 'relative',
      overflow: 'hidden',
    },
    bannerContent: {
      position: 'relative',
      width: '100%',
      paddingTop: '40%',
      minHeight: 324,
      backgroundColor: theme.palette.secondary.main,

      '& > img': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    bannerText: {
      color: '#fff',
    },
    actionWrapper: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      zIndex: 999,
    },
    actionButton: {
      color: '#fff',
      background: theme.palette.secondary.main,
      marginLeft: theme.spacing(1),
      '&:hover': {
        background: theme.palette.secondary.light,
      },
    },
    backButton: {
      position: 'absolute',
      top: theme.spacing(1),
      left: theme.spacing(1),
      color: '#fff',
    },
    loginButton: {
      textTransform: 'unset',
      backgroundColor: theme.palette.grey['200'],
      borderRadius: theme.spacing(1.5),
      height: 56,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
)

export const BannerAdvertisementsPage = () => {
  const { placement } = useParams<{ placement: 'login' | 'menu' }>()
  const [{ places, data }] = useAuth()
  const classes = useStyles()
  const history = useHistory()
  const [carouselIndex, setCarouselIndex] = useState<number>(0)
  const [imgSrc, setImgSrc] = useState<string | ArrayBuffer | null>(null)
  const [editing, setEditing] = useState<boolean>(false)
  const [cropConfigs, setCropConfigs] = useState<Crop>({ aspect: 10 / 4, unit: '%' })
  const imageRef = useRef<HTMLImageElement | null>(null)

  const uploadFile = useUploadAdsFile()
  const advertisements = useGetAdvertisementsQuery({ placeId: places[0], placement })
  const createAdvertisement = useCreateAdvertisementMutation()

  const currentAd = useMemo(() => advertisements.data?.[carouselIndex] || null, [advertisements.data, carouselIndex])

  const isEditable = useMemo(() => {
    if (data?.role === 'medium') {
      return currentAd?.editable
    } else {
      return true
    }
  }, [currentAd?.editable, data?.role])

  const onPhotoAdd = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!!event.target.files?.length) {
      const files = Array.from(event.target.files || [])
      const file = files[0]
      const reader = new FileReader()
      reader.onload = () => {
        setImgSrc(reader.result)
        event.target.files = null
      }
      reader.readAsDataURL(file)
      setEditing(true)
    }
  }, [])

  const resetResizer = useCallback(() => {
    imageRef.current = null
    setImgSrc(null)
    setCropConfigs({ aspect: 10 / 4, unit: '%' })
  }, [])

  const onPhotoUpload = useCallback(async () => {
    if (!imageRef.current) {
      return null
    }

    const file = await getCroppedImg(imageRef.current, cropConfigs)

    try {
      uploadFile.mutate(
        { file, type: 'ads' },
        {
          onSuccess: (url) => {
            createAdvertisement.mutate({
              id: currentAd?.id,
              placeId: places[0],
              source: url,
              type: 'image',
              placement,
              skippableTime: 0,
            })
            resetResizer()
          },
        },
      )
    } catch (e) {
      alert(e.message)
    }
  }, [createAdvertisement, cropConfigs, currentAd?.id, placement, places, resetResizer, uploadFile])

  const onRemove = useCallback(() => {
    if (!currentAd) {
      return null
    }

    createAdvertisement.mutate({
      id: currentAd.id,
      placeId: places[0],
      source: null,
      type: currentAd.type,
      placement,
      skippableTime: currentAd.skippableTime,
    })
  }, [createAdvertisement, currentAd, placement, places])

  useEffect(() => {
    if (places.length !== 1) {
      history.push('/')
    }
  }, [history, places])

  useEffect(() => {
    const handler = () => setCropConfigs((prevState) => ({ ...prevState, width: window.innerWidth }))

    handler()
    window.addEventListener('resize', handler)

    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [])

  if (places.length !== 1) {
    return null
  }

  if (advertisements.isLoading) {
    return null
  }

  if (editing && !!imgSrc) {
    return (
      <Box>
        {(createAdvertisement.isLoading || uploadFile.isLoading) && (
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color='inherit' />
          </Backdrop>
        )}
        <Box className={classes.actionWrapper}>
          <IconButton onClick={onPhotoUpload} component='span' className={classes.actionButton}>
            <Icons.Check />
          </IconButton>
          <IconButton onClick={resetResizer} component='span' className={classes.actionButton}>
            <Icons.Close />
          </IconButton>
        </Box>
        <ReactCrop
          src={imgSrc.toString()}
          onImageLoaded={(img) => (imageRef.current = img)}
          ruleOfThirds
          onChange={setCropConfigs}
          crop={cropConfigs}
          keepSelection
          minWidth={256}
        />
      </Box>
    )
  }

  return (
    <Box>
      {(createAdvertisement.isLoading || uploadFile.isLoading) && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      <Hidden xsUp implementation='css'>
        <input onChange={onPhotoAdd} id='advertisement-upload' type='file' />
      </Hidden>
      <Box className={classes.header}>
        {!!advertisements.data?.length && (
          <Box className={classes.bannerContainer}>
            <Carousel index={carouselIndex} onChange={setCarouselIndex} autoPlay={false}>
              {advertisements.data.map((item) => (
                <Box className={classes.bannerContent}>
                  <img src={item.source} alt='' />
                </Box>
              ))}
            </Carousel>
          </Box>
        )}
        {!advertisements.data?.length && (
          <Box className={classes.banner}>
            <Box className={classes.bannerContent}>
              <Typography className={classes.bannerText} variant='h5'>
                Reklam üçün yer ayırılmayıb...
              </Typography>
            </Box>
          </Box>
        )}
        <IconButton className={classes.backButton} onClick={() => history.go(-1)}>
          <Icons.ArrowBack />
        </IconButton>
        {!!advertisements.data?.length && (
          <Box className={classes.actionWrapper}>
            <label htmlFor='advertisement-upload'>
              <IconButton disabled={!isEditable} component='span' className={classes.actionButton}>
                <Icons.CloudUpload />
              </IconButton>
            </label>
            <IconButton disabled={!isEditable} onClick={onRemove} className={classes.actionButton}>
              <Icons.Delete />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box paddingTop={4}>
        <Container>
          <Grid spacing={2} container>
            {new Array(8).fill(null).map((_, index) => (
              <Grid key={index} xs={12} md={6} item>
                <Button size='large' className={classes.loginButton} fullWidth />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}
