import { ChangeEvent, FC, useCallback, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Typography,
  useTheme
} from "@material-ui/core";
import * as Icons from '@material-ui/icons'
import { baseFormStyles } from '@infra/styles'

import { useUploadFile } from '@modules/upload'

import { useCreatePlaceMutation } from '../hooks'
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(baseFormStyles)

export type EditPlaceFormValues = {
  id: string
  name: string
  address: string
  phoneNumber: string
  avatar: string | null
}

export type EditPlaceProps = {
  initialValues: EditPlaceFormValues
  onClose: () => void
}

export const EditPlace: FC<EditPlaceProps> = ({ initialValues, onClose }) => {
  const theme = useTheme()
  const classes = useStyles()
  const uploadPhoto = useUploadFile()
  const createPlace = useCreatePlaceMutation()

  const [values, setValues] = useState<EditPlaceFormValues>(initialValues)

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues((prevState) => ({ ...prevState, [event.target.name]: event.target.value }))
  }, [])

  const onAvatarRemove = useCallback(() => {
    setValues((prevState) => ({ ...prevState, avatar: null }))
  }, [])

  const onAvatarAdd = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        uploadPhoto.mutate(
          { type: 'logo', file: Array.from(event.target.files || [])[0] },
          {
            onSuccess: (url) => {
              setValues((prevState) => ({ ...prevState, avatar: url }))
            },
          },
        )
        event.target.files = null
      }
    },
    [uploadPhoto],
  )

  const onSubmit = useCallback(() => {
    createPlace.mutate(values, { onSuccess: () => onClose() })
  }, [createPlace, onClose, values])

  return (
    <Paper elevation={1}>
      <Box paddingX={2} paddingY={1} position='relative'>
        <IconButton className={classes.close} onClick={onClose}>
          <Icons.Close />
        </IconButton>
        <Box paddingY={1} borderBottom={`1px solid ${theme.palette.grey['300']}`}>
          <Typography variant='h6'>İaşə obyekti məlumatları</Typography>
        </Box>
        {createPlace.error?.errors?.avatar && (
          <Alert severity='warning' variant='outlined'>
            Avatar: {createPlace.error?.errors?.avatar}
          </Alert>
        )}
        <Box className={classes.box}>
          <Box className={classes.avatarBox}>
            <Hidden xsUp implementation='css'>
              <input id={`edit-place-avatar-upload-${initialValues.id}`} onChange={onAvatarAdd} type='file' />
            </Hidden>
            <Box className={classes.avatar}>
              <Avatar src={values.avatar || undefined}>{values.name.substring(0, 2)}</Avatar>
            </Box>
            {values.avatar ? (
              <IconButton onClick={onAvatarRemove} color='primary' className={classes.uploadButton}>
                <Icons.Delete />
              </IconButton>
            ) : (
              <label htmlFor={`edit-place-avatar-upload-${initialValues.id}`}>
                <IconButton
                  disabled={uploadPhoto.isLoading}
                  component='span'
                  color='primary'
                  className={classes.uploadButton}
                >
                  {!uploadPhoto.isLoading ? <Icons.CloudUpload /> : <CircularProgress />}
                </IconButton>
              </label>
            )}
          </Box>
          <Box flex={1}>
            <Grid spacing={2} container>
              <Grid xs={12} item>
                <TextField
                  fullWidth
                  error={!!createPlace.error?.errors?.name}
                  helperText={createPlace.error?.errors?.name}
                  value={values.name}
                  onChange={onInputChange}
                  name='name'
                  variant='outlined'
                  label='Ad'
                  placeholder='İaşə obyektinin adını daxil edin...'
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  fullWidth
                  error={!!createPlace.error?.errors?.address}
                  helperText={createPlace.error?.errors?.address}
                  onChange={onInputChange}
                  value={values.address}
                  name='address'
                  variant='outlined'
                  label='Ünvan'
                  placeholder='İaşə obyektinin ünvanını daxil edin...'
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  fullWidth
                  error={!!createPlace.error?.errors?.phoneNumber}
                  helperText={createPlace.error?.errors?.phoneNumber}
                  value={values.phoneNumber}
                  onChange={onInputChange}
                  name='phoneNumber'
                  variant='outlined'
                  label='Mobil telefon'
                  placeholder='İaşə obyektinin mobil telefonunu daxil edin...'
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box paddingBottom={1} display='flex' justifyContent='flex-end'>
          <Button onClick={onSubmit} variant='contained' size='large' color='primary'>
            Təsdiqlə
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}
