import { FC, useCallback, useMemo, useState } from 'react'
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'

import { Place } from '../interfaces'
import { PlaceToolbar } from '../components'
import { EditPlace, EditPlaceFormValues } from '../containers'
import { useRemovePlaceMutation } from '../hooks'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > *:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
  }),
)

export type PlaceBoxProps = {
  data: Place
}

export const PlaceBox: FC<PlaceBoxProps> = ({ data }) => {
  const classes = useStyles()
  const removePlace = useRemovePlaceMutation()

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [removing, setRemoving] = useState<boolean>(false)

  const onEdit = useCallback(() => {
    setIsEditing(true)
  }, [])

  const onClose = useCallback(() => {
    setIsEditing(false)
  }, [])

  const onRemove = useCallback(() => {
    removePlace.mutate({ id: data.id })
  }, [data.id, removePlace])

  const initialFormValues = useMemo<EditPlaceFormValues>(
    () => ({
      id: data.id,
      name: data.name,
      address: data.address,
      avatar: data.avatar,
      phoneNumber: data.phoneNumber || '',
    }),
    [data.address, data.avatar, data.id, data.name, data.phoneNumber],
  )

  if (isEditing) {
    return <EditPlace initialValues={initialFormValues} onClose={onClose} />
  }

  return (
    <Box className={classes.root}>
      <PlaceToolbar avatar={data.avatar} title={data.name} onRemove={() => setRemoving(true)} onEdit={onEdit} />
      {isEditing && <EditPlace initialValues={initialFormValues} onClose={onClose} />}
      <Dialog open={removing} onClose={() => setRemoving(true)}>
        <DialogTitle>Diqqət</DialogTitle>
        <DialogContent>
          <DialogContentText>İaşə obyektini silməyə əminsinizmi?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRemoving(false)}>Xeyr</Button>
          <Button onClick={onRemove}>Bəli</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
