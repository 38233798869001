export const clearEmptyString = (object) => {
  const newObject = {}

  Object.keys(object).forEach((key) => {
    if (object[key] !== '' && object[key] !== undefined) {
      newObject[key] = object[key]
    }
  })

  return newObject
}
