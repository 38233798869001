import { useMutation } from 'react-query'
import { FormException } from '@core/exceptions'

import { REMOVE_PLACE, RemovePlaceMutationValidationError, RemovePlaceMutationVariables } from '../mutations'
import { queryClient } from '../../../index'

export const useRemovePlaceMutation = () => {
  return useMutation<void, FormException<RemovePlaceMutationValidationError>, RemovePlaceMutationVariables>(
    REMOVE_PLACE,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['places'])
      },
    },
  )
}
