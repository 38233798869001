import { Route, Switch } from 'react-router-dom'

import { AdvertisementPermissionsPage } from '../pages'

export const AdvertisementsRouter = () => {
  return (
    <Switch>
      <Route path='/advertisements/permissions' component={AdvertisementPermissionsPage} />
    </Switch>
  )
}

export default AdvertisementsRouter