import { Suspense, lazy } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { Location } from 'history'

import { Layout } from '@infra/layout'
import { useAuth } from '@modules/auth'

import { AuthenticationAdvertisementPage, BannerAdvertisementsPage } from '@modules/advertisements'

const AuthRouter = lazy(() => import('@modules/auth/router/auth.router'))

const UsersRouter = lazy(() => import('@modules/users/router/users.router'))

const UsersModalRouter = lazy(() => import('@modules/users/router/users.modal-router'))

const PlacesModalRouter = lazy(() => import('@modules/places/router/places.modal-router'))

const AdvertisementsRouter = lazy(() => import('@modules/advertisements/router/advertisements.router'))

const LoginMethodsRouter = lazy(() => import('@modules/login-methods/router/login-methods.router'))

const NetworkRouter = lazy(() => import('@modules/network/router/network.router'))

const LoginDataRouter = lazy(() => import('@modules/login-data/router/login-data.router'))

const MenusRouter = lazy(() => import('@modules/menus/router/menus.router'))

const DeviceSettingsRouter = lazy(() => import('@modules/device-settings/router/device-settings.router'))

const SurveysRouter = lazy(() => import('@modules/surveys/router/surveys.router'))

const OrdersRouter = lazy(() => import('@modules/orders/router/orders.router'))

export const Router = () => {
  const location = useLocation<{ background?: Location } | undefined>()
  const [auth] = useAuth()

  if (auth.isLoading) {
    return null
  }

  if (!auth.data) {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Switch location={location.state?.background || location}>
          <Route path='/auth' component={AuthRouter} />
          <Redirect to='/auth/login' />
        </Switch>
      </Suspense>
    )
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch location={location.state?.background || location}>
        <Route path='/advertisements/:placement(login|menu)' exact={true} component={BannerAdvertisementsPage} />
        <Route path='/advertisements/authentication' exact={true} component={AuthenticationAdvertisementPage} />
        <Route path='/'>
          <Layout>
            <Switch>
              <Route path='/users' component={UsersRouter} />
              <Route path='/advertisements' component={AdvertisementsRouter} />
              <Route path='/login-methods' component={LoginMethodsRouter} />
              <Route path='/network' component={NetworkRouter} />
              <Route path='/device-settings' component={DeviceSettingsRouter} />
              <Route path='/login-data' component={LoginDataRouter} />
              <Route path='/menus' component={MenusRouter} />
              <Route path='/surveys' component={SurveysRouter} />
              <Route path='/orders' component={OrdersRouter} />
            </Switch>
          </Layout>
        </Route>
      </Switch>
      <Switch>
        <Route path='/'>
          <Route path='/users' component={UsersModalRouter} />
          <Route path='/places' component={PlacesModalRouter} />
        </Route>
      </Switch>
    </Suspense>
  )
}
