import { createContext, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'

export type LayoutContextValue = [{ sidebar: { isOpen: boolean } }, { toggleSidebar: (value?: boolean) => any }]

const defaultValue: LayoutContextValue = [{ sidebar: { isOpen: false } }, { toggleSidebar: () => {} }]

export const LayoutContext = createContext<LayoutContextValue>(defaultValue)

export const LayoutProvider = memo(({ children }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false)

  const toggleSidebar = useCallback((value?: boolean) => {
    setSidebarIsOpen((prevState) => (value !== undefined ? value : !prevState))
  }, [])

  const state = useMemo(() => ({ sidebar: { isOpen: sidebarIsOpen } }), [sidebarIsOpen])

  const handlers = useMemo(() => ({ toggleSidebar }), [toggleSidebar])

  const value = useMemo<[typeof state, typeof handlers]>(() => [state, handlers], [handlers, state])

  useEffect(() => {
    setSidebarIsOpen(isDesktop)
  }, [isDesktop])

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
})
