import { useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Location, Path } from 'history'

export const useCloseModal = () => {
  const { push } = useHistory()
  const location = useLocation<{ background?: Location }>()

  return useCallback(
    (path: Path, state?: any) => {
      if (location.state?.background) {
        push(location.state.background)
      } else {
        push(path, state)
      }
    },
    [location.state?.background, push],
  )
}
