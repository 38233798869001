import { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core'

import { Appbar, Sidebar, Content } from '../components'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },
  }),
)

export const Layout: FC = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Appbar />
      <Sidebar />
      <Content>{children}</Content>
    </div>
  )
}
