import { MutationFunction } from 'react-query'

export const LOGOUT: MutationFunction<void> = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1/auth/logout/`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  })

  if (!response.ok) {
    const { message } = await response.json()

    throw new Error(message)
  }
}
