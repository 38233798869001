import { Box, createStyles, makeStyles } from '@material-ui/core'
import { TitlePortal } from '@infra/layout'

import { usePlacesQuery } from '@modules/places'

import { AdsBox } from '../containers'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > *:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
  }),
)

export const AdvertisementPermissionsPage = () => {
  const classes = useStyles()
  const places = usePlacesQuery()

  return (
    <Box className={classes.root}>
      <TitlePortal>Reklam icazələri</TitlePortal>
      {places.data?.map((place) => (
        <AdsBox key={place.id} data={place} />
      ))}
    </Box>
  )
}
