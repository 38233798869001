import { MutationFunction } from 'react-query'
import { FormException } from '@core/exceptions'

export type UpdateAdvertisementPermissionMutationVariables = {
  id: number
  placeId: string
  editable?: boolean
}

export type UpdateAdvertisementPermissionMutationValidationError = Partial<{
  editable: string
}>

export const UPDATE_ADVERTISEMENT_PERMISSION: MutationFunction<
  void,
  UpdateAdvertisementPermissionMutationVariables
> = async ({ id, placeId, editable }) => {
  const body = JSON.stringify({ perm: editable })

  const url = `${process.env.REACT_APP_API_HOST}/api/v1/locations/${placeId}/media/${id}/`

  const response = await fetch(url, {
    method: 'POST',
    body,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })

  if (response.status === 400) {
    const { message } = await response.json()

    throw new FormException<UpdateAdvertisementPermissionMutationValidationError>('Məlumatlar yalnış daxil edilib.', {
      editable: message.perm?.join('. '),
    })
  } else if (!response.ok) {
    const { message } = await response.json()
    throw new FormException(message)
  }
}
