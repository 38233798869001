import { QueryFunction } from 'react-query'
import { placeToDomain } from '@modules/places'

import { Profile } from '../interfaces'

export type GetProfileQueryData = Profile
export type GetProfileQueryKey = ['auth', 'profile']

export const GET_PROFILE: QueryFunction<GetProfileQueryData, GetProfileQueryKey> = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1/profile/`, {
    credentials: 'include',
  })

  if (response.ok) {
    const { body } = await response.json()
    return {
      id: body.id,
      name: body.name || '',
      email: body.email,
      avatar: body.photo,
      role: body.type || 'superadmin',
      places: body.locations.map((item) => placeToDomain(item)),
    }
  } else {
    const { message } = await response.json()

    throw new Error(message)
  }
}
