import { Fragment, FC, useState, useMemo } from 'react'

import { Place, PlaceToolbar } from '@modules/places'

import { useGetAdvertisementsQuery } from '../hooks'
import { AdsPermissions } from '@modules/advertisements'

export const AdsBox: FC<{ data: Place }> = ({ data }) => {
  const [editing, setEditing] = useState<boolean>(false)
  const advertisements = useGetAdvertisementsQuery({ placeId: data.id }, { enabled: editing })

  const advertisementsData = useMemo(() => {
    return {
      login: advertisements.data?.filter((ad) => ad.placement === 'login') || [],
      authentication: advertisements.data?.filter((ad) => ad.placement === 'authentication') || [],
      menu: advertisements.data?.filter((ad) => ad.placement === 'menu') || [],
    }
  }, [advertisements.data])

  return (
    <Fragment>
      <PlaceToolbar
        avatar={data.avatar}
        closable={editing}
        title={data.name}
        onEdit={() => setEditing(true)}
        onClose={() => setEditing(false)}
      />
      {editing && <AdsPermissions placeId={data.id} data={advertisementsData} />}
    </Fragment>
  )
}
