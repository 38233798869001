import { useQuery, UseQueryOptions } from 'react-query'

import { GET_PLACE_BY_ID, GetPlaceByIdQueryData, GetPlaceByIdQueryKey } from '../queries'

export const usePlaceByIdQuery = (
  id?: string,
  options?: UseQueryOptions<GetPlaceByIdQueryData, Error, GetPlaceByIdQueryData, GetPlaceByIdQueryKey>,
) => {
  return useQuery<GetPlaceByIdQueryData, Error, GetPlaceByIdQueryData, GetPlaceByIdQueryKey>(
    ['places', id || ''],
    GET_PLACE_BY_ID,
    { enabled: !!id, ...options },
  )
}
