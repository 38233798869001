import 'react-image-crop/dist/ReactCrop.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { QueryClient, QueryClientProvider } from 'react-query'

import { Router } from '@infra/router'
import { LayoutProvider, theme } from '@infra/layout'

import { AuthProvider } from '@modules/auth'

import reportWebVitals from './reportWebVitals'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={theme}>
          <LayoutProvider>
            <CssBaseline />
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </LayoutProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </AuthProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
