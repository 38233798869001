import { AppBar, Box, createStyles, IconButton, makeStyles, Toolbar } from '@material-ui/core'
import * as Icons from '@material-ui/icons'

import { useLayout, UserSelection } from '@infra/layout'

const useStyles = makeStyles((theme) =>
  createStyles({
    appbarOpen: {
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - 240px)`,
        marginLeft: 240,
      },
    },
    appbarClose: {
      [theme.breakpoints.up('lg')]: {
        marginLeft: 0,
      },
    },
    title: {
      whiteSpace: 'nowrap',
      fontSize: 18,
      fontWeight: 500,
    },
    menuButton: {},
    toolbarItems: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '100%',
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.up('lg')]: {
        '& > *:not(:last-child)': {
          marginRight: theme.spacing(6),
        },
      },
    },
  }),
)

export const Appbar = () => {
  const classes = useStyles()
  const [{ sidebar }, { toggleSidebar }] = useLayout()

  return (
    <AppBar color='default' position='fixed' className={sidebar.isOpen ? classes.appbarOpen : classes.appbarClose}>
      <Toolbar>
        <IconButton onClick={() => toggleSidebar()} color='inherit' edge='start' className={classes.menuButton}>
          <Icons.Menu />
        </IconButton>
        <Box className={classes.title} id='appbar-title-portal-area' />
        <Box className={classes.toolbarItems}>
          <UserSelection />
        </Box>
      </Toolbar>
    </AppBar>
  )
}
