import { MutationFunction } from 'react-query'
import { FormException } from '@core/exceptions'

export type RemovePlaceMutationVariables = {
  id: string
}

export type RemovePlaceMutationValidationError = Partial<{
  id: string
}>

export const REMOVE_PLACE: MutationFunction<void, RemovePlaceMutationVariables> = async ({ id }) => {
  const url = `${process.env.REACT_APP_API_HOST}/api/v1/locations/${id}/`

  const response = await fetch(url, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })

  if (!response.ok) {
    const { message } = await response.json()
    throw new FormException(message)
  }
}
