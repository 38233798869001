import { MutationFunction } from 'react-query'

export type UploadAdsFileMutationData = string
export type UploadAdsFileMutationVariables = { type: string; file: Blob }

export const UPLOAD_ADS_FILE_MUTATION: MutationFunction<
  UploadAdsFileMutationData,
  UploadAdsFileMutationVariables
> = async ({ type, file }) => {
  const body = new FormData()
  body.append('sub', type)
  body.append('file', file)

  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1/media/upload/`, {
    method: 'PUT',
    body,
    credentials: 'include',
  })

  if (response.ok) {
    const {
      body: { url },
    } = await response.json()

    return url
  } else {
    throw new Error('Əməliyyat aparıla bilmədi')
  }
}
