import { MutationFunction } from 'react-query'

export type LoginMutationVariables = { email: string; password: string }

export const LOGIN: MutationFunction<void, LoginMutationVariables> = async (formData) => {
  const body = JSON.stringify(formData)

  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1/auth/login/`, {
    body,
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })

  if (response.status === 400) {
    const { message } = await response.json()

    throw new Error(Object.values(message).flat().join('. '))
  }

  if (!response.ok) {
    const { message } = await response.json()

    throw new Error(message)
  }
}
