import { MutationFunction } from 'react-query'
import { FormException } from '@core/exceptions'

export type RemoveAdvertisementMutationVariables = {
  placeId: string
  advertisementId: number
}

export const REMOVE_ADVERTISEMENT: MutationFunction<void, RemoveAdvertisementMutationVariables> = async ({
  placeId,
  advertisementId,
}) => {
  const url = `${process.env.REACT_APP_API_HOST}/api/v1/locations/${placeId}/media/${advertisementId}`

  const response = await fetch(url, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })

  if (!response.ok) {
    const { message } = await response.json()
    throw new FormException(message)
  }
}
