import { Avatar, Box, Button, createStyles, makeStyles, Typography } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { FC } from 'react'
import { Place } from '@modules/places'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    main: {
      flex: 1,
      display: 'block',
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    avatar: {
      marginRight: theme.spacing(2),
    },
    title: {
      marginRight: theme.spacing(4),
    },
    ratingRoot: {
      display: 'flex',
      color: theme.palette.warning.main,
      fontSize: 28,
      marginRight: theme.spacing(1),
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
  }),
)

export const PlaceHeader: FC<{ data: Place }> = ({ data }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.main}>
        <Box display='flex' alignItems='center'>
          <Avatar className={classes.avatar}>EY</Avatar>
          <Typography className={classes.title} variant='h5'>
            {data.name}
          </Typography>
        </Box>
        <Box display='flex' alignItems='center'>
          <Box className={classes.ratingRoot}>
            {new Array(5).fill(null).map((_, index) => {
              if (index + 1 <= Math.round(data.rating)) {
                return <Icons.Favorite key={index} color='inherit' fontSize='inherit' />
              }

              return <Icons.FavoriteBorder key={index} color='inherit' fontSize='inherit' />
            })}
          </Box>
          <Typography variant='h5'>{data.rating.toFixed(1)}</Typography>
        </Box>
      </Box>
      <Box>
        <Button
          component='a'
          target='_blank'
          rel='noreferrer noopener'
          href={`${process.env.REACT_APP_API_HOST}/menu/${data.menuUrl}/`}
          variant='contained'
          color='secondary'
          size='small'
        >
          Ön izləmə
        </Button>
      </Box>
    </Box>
  )
}
