import { Box, CircularProgress, createStyles, makeStyles } from '@material-ui/core'

import { PlaceBox } from '../containers'
import { usePlacesQuery } from '../hooks'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > *:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
  }),
)

export const PlacesPage = () => {
  const classes = useStyles()
  const places = usePlacesQuery()

  return (
    <Box className={classes.root}>
      {places.isLoading && (
        <Box textAlign='center'>
          <CircularProgress />
        </Box>
      )}
      {places.data?.map((place) => (
        <PlaceBox key={place.id} data={place} />
      ))}
    </Box>
  )
}
