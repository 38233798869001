import { useMutation } from 'react-query'
import { FormException } from '@core/exceptions'

import { CREATE_PLACE, CreatePlaceMutationValidationError, CreatePlaceMutationVariables } from '../mutations'
import { queryClient } from '../../../index'

export const useCreatePlaceMutation = () => {
  return useMutation<void, FormException<CreatePlaceMutationValidationError>, CreatePlaceMutationVariables>(
    CREATE_PLACE,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['places'])
      },
    },
  )
}
