import { useMutation } from 'react-query'
import { LOGIN, LoginMutationVariables } from '@modules/auth/mutations'
import { queryClient } from '../../../index'

export const useLoginMutation = () => {
  return useMutation<void, Error, LoginMutationVariables>(LOGIN, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['auth'])
    },
  })
}
