import { Fragment, useMemo, useState } from 'react'
import {
  Box,
  Collapse,
  createStyles,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { useLayout } from '@infra/layout'
import { ListLinkItem } from '@core/ui'
import { useAuth } from '@modules/auth'

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbar: {
      ...theme.mixins.toolbar,
      textAlign: 'center',
      '& > img': {
        height: 56,
      },
    },
    drawer: {
      [theme.breakpoints.up('lg')]: {
        width: 240,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: 240,
    },
    nestedItem: {
      paddingLeft: theme.spacing(4),
    },
    divider: {
      backgroundColor: theme.palette.primary.main,
    },
  }),
)

export const Sidebar = () => {
  const [{ places, data }] = useAuth()
  const [{ sidebar }, { toggleSidebar }] = useLayout()
  const theme = useTheme()
  const classes = useStyles()
  const [statisticsIsOpen, setStatisticsIsOpen] = useState<boolean>(false)
  const [ordersIsOpen, setOrdersIsOpen] = useState<boolean>(false)
  const [advertisementsIsOpen, setAdvertisementsIsOpen] = useState<boolean>(false)

  const singlePlaceSelected = useMemo(() => places.length === 1, [places.length])

  const drawerClasses = useMemo(
    () => ({
      paper: classes.drawerPaper,
    }),
    [classes.drawerPaper],
  )

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <img src='/adify.png' alt='' />
      </div>
      <Divider />
      <Box paddingX={2} paddingTop={2} paddingBottom={1}>
        <Typography variant='body1'>Ümumi</Typography>
      </Box>
      <List>
        <ListItem disabled onClick={() => setStatisticsIsOpen((prevState) => !prevState)} button>
          <ListItemIcon>
            <Icons.BarChart />
          </ListItemIcon>
          <ListItemText primary='Statistika' />
          {statisticsIsOpen ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
        </ListItem>
        <Collapse in={statisticsIsOpen}>
          <List component='div'>
            <ListLinkItem to='/statistics/traffics' className={classes.nestedItem}>
              <ListItemText primary='Traffik' />
            </ListLinkItem>
            <ListLinkItem to='/statistics/times' className={classes.nestedItem}>
              <ListItemText primary='Zaman' />
            </ListLinkItem>
            <ListLinkItem to='/statistics/devices' className={classes.nestedItem}>
              <ListItemText primary='Cihazlar' />
            </ListLinkItem>
            <ListLinkItem to='/statistics/advertisements' className={classes.nestedItem}>
              <ListItemText primary='Reklam' />
            </ListLinkItem>
            <ListLinkItem to='/statistics/operators' className={classes.nestedItem}>
              <ListItemText primary='Operatorlar' />
            </ListLinkItem>
          </List>
        </Collapse>
        <ListLinkItem disabled to='/sms'>
          <ListItemIcon>
            <Icons.Sms />
          </ListItemIcon>
          <ListItemText primary='SMS' />
        </ListLinkItem>
        <ListLinkItem disabled to='/email'>
          <ListItemIcon>
            <Icons.Mail />
          </ListItemIcon>
          <ListItemText primary='E-Mail' />
        </ListLinkItem>
        <ListItem disabled={!singlePlaceSelected} onClick={() => setOrdersIsOpen((prevState) => !prevState)} button>
          <ListItemIcon>
            <Icons.ShoppingBasket />
          </ListItemIcon>
          <ListItemText primary='Sifarişlər' />
          {ordersIsOpen ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
        </ListItem>
        <Collapse in={ordersIsOpen}>
          <List component='div'>
            <ListLinkItem to='/orders/main' className={classes.nestedItem}>
              <ListItemText primary='Gələn sifarişlər' />
            </ListLinkItem>
            <ListLinkItem disabled to='/orders/table' className={classes.nestedItem}>
              <ListItemText primary='Masa nömrəsi' />
            </ListLinkItem>
            <ListLinkItem to='/orders/settings' className={classes.nestedItem}>
              <ListItemText primary='Tənzimləmələr' />
            </ListLinkItem>
          </List>
        </Collapse>
        <ListLinkItem disabled={!singlePlaceSelected} to='/surveys'>
          <ListItemIcon>
            <Icons.List />
          </ListItemIcon>
          <ListItemText primary='Sorğu' />
        </ListLinkItem>
        <ListLinkItem to='/login-data'>
          <ListItemIcon>
            <Icons.AccountCircle />
          </ListItemIcon>
          <ListItemText primary='İstifadəçi məlumatları' />
        </ListLinkItem>
        <Divider className={classes.divider} />
        <Box paddingX={2} paddingTop={2} paddingBottom={1}>
          <Typography variant='body1'>Tənzimləmələr</Typography>
        </Box>
        <ListLinkItem disabled={!singlePlaceSelected} to='/device-settings'>
          <ListItemIcon>
            <Icons.Settings />
          </ListItemIcon>
          <ListItemText primary='Cihaz sazlama' />
        </ListLinkItem>
        <ListLinkItem disabled={!singlePlaceSelected} to='/login-methods'>
          <ListItemIcon>
            <Icons.SupervisedUserCircleOutlined />
          </ListItemIcon>
          <ListItemText primary='Login metodları' />
        </ListLinkItem>
        <ListItem
          disabled={!singlePlaceSelected}
          onClick={() => setAdvertisementsIsOpen((prevState) => !prevState)}
          button
        >
          <ListItemIcon>
            <Icons.VolumeDown />
          </ListItemIcon>
          <ListItemText primary='Reklamlar' />
          {statisticsIsOpen ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
        </ListItem>
        <Collapse in={advertisementsIsOpen}>
          <List component='div'>
            <ListLinkItem disabled={!singlePlaceSelected} to='/advertisements/login' className={classes.nestedItem}>
              <ListItemText primary='Giriş' />
            </ListLinkItem>
            <ListLinkItem disabled={!singlePlaceSelected} to='/advertisements/menu' className={classes.nestedItem}>
              <ListItemText primary='Menyu' />
            </ListLinkItem>
            <ListLinkItem
              disabled={!singlePlaceSelected}
              to='/advertisements/authentication'
              className={classes.nestedItem}
            >
              <ListItemText primary='Reklam izləyərək daxil ol' />
            </ListLinkItem>
          </List>
        </Collapse>
        <ListLinkItem disabled={!singlePlaceSelected} to='/menus'>
          <ListItemIcon>
            <Icons.ListAlt />
          </ListItemIcon>
          <ListItemText primary='Menyular' />
        </ListLinkItem>
        <ListLinkItem to='/network'>
          <ListItemIcon>
            <Icons.SettingsInputAntenna />
          </ListItemIcon>
          <ListItemText primary='Qoşulmalar' />
        </ListLinkItem>
        {data?.role !== 'medium' && (
          <Fragment>
            <Divider className={classes.divider} />
            <Box paddingX={2} paddingTop={2} paddingBottom={1}>
              <Typography variant='body1'>Super user</Typography>
            </Box>
            <ListLinkItem to='/users'>
              <ListItemIcon>
                <Icons.GroupAddOutlined />
              </ListItemIcon>
              <ListItemText primary='User management' />
            </ListLinkItem>
            <ListLinkItem to='/advertisements/permissions'>
              <ListItemIcon>
                <Icons.Tune />
              </ListItemIcon>
              <ListItemText primary='Reklam icazələri' />
            </ListLinkItem>
          </Fragment>
        )}
      </List>
    </div>
  )

  return (
    <nav className={classes.drawer}>
      <Hidden lgUp implementation='js'>
        <Drawer
          variant='temporary'
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={sidebar.isOpen}
          onClose={() => toggleSidebar()}
          classes={drawerClasses}
        >
          {drawer}
        </Drawer>
      </Hidden>
      {sidebar.isOpen && (
        <Hidden mdDown implementation='js'>
          <Drawer classes={drawerClasses} variant='permanent' open>
            {drawer}
          </Drawer>
        </Hidden>
      )}
    </nav>
  )
}
