import { useQuery, UseQueryOptions } from 'react-query'

import { GET_ADVERTISEMENTS, GetAdvertisementsQueryData, GetAdvertisementsQueryKey } from '../queries'
import { AdvertisementPlacement } from '../interfaces'

export const useGetAdvertisementsQuery = (
  query?: Partial<{ placeId: string; placement: AdvertisementPlacement }>,
  options?: UseQueryOptions<GetAdvertisementsQueryData, Error, GetAdvertisementsQueryData, GetAdvertisementsQueryKey>,
) => {
  return useQuery<GetAdvertisementsQueryData, Error, GetAdvertisementsQueryData, GetAdvertisementsQueryKey>(
    ['places', query?.placeId, 'advertisements', { placement: query?.placement }],
    GET_ADVERTISEMENTS,
    { enabled: !!query?.placement && !!query?.placeId, ...options },
  )
}
