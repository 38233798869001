import { FC, MouseEventHandler } from 'react'
import { Avatar, Box, createStyles, IconButton, makeStyles, Paper, Typography } from '@material-ui/core'
import * as Icons from '@material-ui/icons'

const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      marginRight: theme.spacing(2),
    },
  }),
)

export type PlaceToolbarProps = {
  avatar?: string | null
  title: string
  onEdit?: MouseEventHandler
  onRemove?: MouseEventHandler
  onClose?: MouseEventHandler
  closable?: boolean
}

export const PlaceToolbar: FC<PlaceToolbarProps> = ({ avatar, title, onEdit, onRemove, onClose, closable }) => {
  const classes = useStyles()

  return (
    <Paper elevation={1}>
      <Box alignItems='center' display='flex'>
        <Box display='flex' alignItems='center' flex={1} padding={1}>
          <Avatar className={classes.avatar} variant='rounded' src={avatar || undefined}>
            {!avatar && title.substring(0, 2)}
          </Avatar>
          <Typography>{title}</Typography>
        </Box>
        {!closable && (
          <Box>
            {onEdit && (
              <IconButton onClick={onEdit}>
                <Icons.Edit />
              </IconButton>
            )}
            {onRemove && (
              <IconButton onClick={onRemove}>
                <Icons.Delete />
              </IconButton>
            )}
          </Box>
        )}
        {closable && onClose && (
          <Box>
            <IconButton onClick={onClose}>
              <Icons.Close />
            </IconButton>
          </Box>
        )}
      </Box>
    </Paper>
  )
}
